<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>			
					<el-button type="success" @click="addnew">新增活动</el-button>					
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="260" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>	
							<el-button @click="editerow(scope.row)" type="warning" size="mini">编辑</el-button>	
							<!-- <el-button @click="setpailie(scope.row)" type="primary" size="mini">排座位</el-button>	 -->
							<el-button @click="qdcode(scope.row)" type="primary" size="mini">签到二维码</el-button>	
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<detail ref="mydetail" :updataQx="2"></detail>
		
		
		
		<!--签到二维码-->
		<el-dialog
		  title="签到二维码"
		  :visible.sync="showcode"
		  width="400px">
		  <div style="width: 100%;display: flex;align-items: center;justify-content: center;">
			  <vue-qr
			      :logoSrc="imageLogo"
			      :text=qr_url
			      :size="300"
			      :margin="5"
			      colorDark="black"
			      colorLight="white"
			  ></vue-qr>
		  </div>
		  <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: flex-end;">
		    <el-button @click="showcode = false">关 闭</el-button>
		    <!-- <el-button type="primary" @click="postqingjia">确 定</el-button> -->
		  </span>
		</el-dialog>
	</div>
</template>

<script>
import detail from './components/detail.vue'
import vueQr from "vue-qr";
	export default{		
		components:{ detail,vueQr },
		data() {
			return{
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'name',
						name: '活动名称',
						width: ''
					},
					{
						prop: 'classify.name',
						name: '所属分类',
						width: '150'
					},
					{
						prop: 'address',
						name: '地点',
						width: ''
					},
					{
						prop: 'activity_start_time',
						name: '开始时间',
						width: '200'
					}
				],
				tableData: [],
				showcode:false,
				qr_url:'',
				imageLogo: require("@/assets/logo.png"), 
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			editerow(row){
				this.$router.push({path:'/system/activity_edit',query:{id:row.id}})
			},
			qdcode(row){
				this.qr_url = this.$URL+'?path=/pages/home/activity_mng/detail/detail&id='+row.id+'&type=scan'
				this.showcode = true
			},
			setpailie(row){
				this.$post({
					url: '/api/wyroomzc/hyzc',
					params: {
						wymeeting_id:row.id
					}
				}).then((res) => {	
					this.$refs.mysetmap.room_name = res.wymeeting.address
					this.$refs.mysetmap.userlist = []
					if(res.list.length>=1){
						this.$refs.mysetmap.userlist = res.list.map((item)=>{
							let obj = {
								user_id:item.user_id,
								name:item.name
							}
							return obj
						})
					}					
					this.$refs.mysetmap.metting_id = row.id
					if(res.zuoci.pai.length==0){
						this.$refs.mysetmap.init()
						this.$refs.mysetmap.dialogVisible = true
					}else{
						let list = []
						res.zuoci.pai.forEach((item,index)=>{
							list[index] = []
							item.lie.forEach((item2,index2)=>{
								item2.name = item2.user?item2.user.name:''
								list[index].push(item2)
							})
						})						
						
						let zxlist=[]
						res.zuoci.zhu.forEach((item,index)=>{
							item.name = item.user?item.user.name:''
							zxlist.push(item)
						})
						
						this.$refs.mysetmap.list = list
						this.$refs.mysetmap.zxset = zxlist
						this.$refs.mysetmap.dialogVisible = true
					}
				})
			},
			showeidt(row){
				this.$post({
					url: '/api/activity/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.form = res
					this.$refs.mydetail.isedite = false
					this.$refs.mydetail.dialogVisible = true		
				})
			},
			changswitch(row){
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/activity/index',
					params: {
						name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {		
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew(){
				this.$router.push('/system/activity_add')
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'activity_list.scss';
</style>